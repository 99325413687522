exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-kategorie-index-js": () => import("./../../../src/pages/kategorie/index.js" /* webpackChunkName: "component---src-pages-kategorie-index-js" */),
  "component---src-pages-pre-clenov-index-js": () => import("./../../../src/pages/pre-clenov/index.js" /* webpackChunkName: "component---src-pages-pre-clenov-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-announcement-js": () => import("./../../../src/templates/announcement.js" /* webpackChunkName: "component---src-templates-announcement-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

